import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { AuthService } from '../../../service';
import { NewUser } from '../../../model/types';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/internal/operators/take';


export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
} 


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy {
  confirmUser = false;
  didFail = false;
  isLoading = false;
  failMessage: string;
  @ViewChild('usrForm', { static: false }) form: NgForm;

  model: NewUser;

  registerForm: FormGroup;
  submitted = false;

  constructor(private authService: AuthService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.minLength(6)]],
      name: ['', Validators.required],
      // phone: ['', Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      phone: ['', Validators.compose([Validators.required, Validators.pattern(/^\+(?:[0-9] ?){6,14}[0-9]$/)])],
      email: ['', [Validators.required, Validators.email]],
      organization: ['', Validators.required],
      // promotionCode: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      disclaimer: ['', Validators.required]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });



    this.authService.authIsLoading.subscribe(
        (isLoading: boolean) => this.isLoading = isLoading
    );
    this.authService.authDidFail.subscribe(
        (didFail: boolean) => this.didFail = didFail
    );

    this.authService.failMessage.subscribe((failMessage: string) => {
    
      const message = failMessage.split('@')[1];
      if (message === 'EmailError.') {
        this.failMessage = 'Please use your Corporate Email id to Register'
      } else if (message === 'AccessCodeError.') {
        this.failMessage = 'Please provide valid Access Code. Contact support@ariadnesolutions.com to get valid access code'
      } else {
        if (failMessage.length > 0 ) {
          this.failMessage = failMessage
        
        } else {
          this.failMessage = 'Something went wrong please contact support@ariadnesolutions.com'
        }
      }
    });
  }

  get f() { return this.registerForm.controls; }

  onSubmit() {
   
    this.submitted = true;
    this.model = Object.assign({}, this.registerForm.value );
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    this.authService.signUp(this.model);
  }

  onDoConfirm() {
    this.confirmUser = true;
  }


  getVerificationPage() {
    this.isLoading = true;
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  ngOnDestroy() {
    this.authService.authDidFail.next(false);
    this.authService.failMessage.next("");
  }
}
