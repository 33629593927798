export const environment = {
    production: false,
    serverUrl: 'https://v3mq54o4ng.execute-api.us-east-2.amazonaws.com/dev',
    bucket: 'lcms-dev-upload',
    userPool: {
      UserPoolId: 'us-east-2_pZssClSY8',
      ClientId: '7k3lqopsg4gkqm4g2b8s2gkc3t',
      region: 'us-east-2'
    },
    identityPoolId: 'us-east-2:2c7a6cd9-3861-463b-99a6-578bca89e288',
    buckets: {
      'us-east-2': 'lcms-dev-upload'
    },
    modules: {
      key: 'analysisType',
      values: [
          {
              'name': 'LCMS',
              'desc': 'LCMS'
          },
         
      ]
  },
    defaultRegion: 'us-east-2',
    cognito: {
      identityPoolId: 'us-east-2:2c7a6cd9-3861-463b-99a6-578bca89e288', // REQUIRED - Amazon Cognito Identity Pool ID
      region: 'us-east-2', // REQUIRED - Amazon Cognito Region
      userPoolId: 'us-east-2_pZssClSY8', // OPTIONAL - Amazon Cognito User Pool ID
      userPoolWebClientId: '7k3lqopsg4gkqm4g2b8s2gkc3t', // OPTIONAL - Amazon Cognito Web Client ID
    },
    Storage: {
      AWSS3: {
        bucket: 'lcms-dev-upload', // REQUIRED -  Amazon S3 bucket
        region: 'us-east-2', // OPTIONAL -  Amazon service region
      }
    }
  };
  const awsconfig = {
    'aws_project_region': 'us-east-2',
    'aws_cognito_region': 'us-east-2',
    'aws_user_pools_id': 'us-east-2_pZssClSY8',
    'aws_user_pools_web_client_id': '7k3lqopsg4gkqm4g2b8s2gkc3t',
    'aws_cognito_mfa_configuration': 'OPTIONAL',
  
  };
  export default awsconfig;
  