import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from '../../../layouts/shared-service';
import { AnalysisDetails, AnalysisDetailsqPCR } from '../../../model/analysis-details.model';
import { DropDownService } from '../../../service/drop-down.service';
import { TemplateService } from '../../../service/template.service';
import { UploadService } from '../../../service/upload.service';

@Component({
  selector: 'app-qpcr-report',
  templateUrl: './qpcr-report.component.html',
  styleUrls: ['./qpcr-report.component.scss']
})
export class QpcrReportComponent implements OnChanges {

  @Input() analysistype: string;
  @Input() editvalues: any;
  @Output() formvalue = new EventEmitter<any>();
  pageTitle = 'Analyze Report/Data';

  analysisFormGroup: FormGroup;
  maxDate;
  // lts80_dialog: FormControl = new FormControl();
  analysisDetails: AnalysisDetails;
  subtype: AnalysisDetails;
  tableNamesList: any[];
  tableNames: any;
  showTable = false;
  countTable = false;
  post: any;
  tableParamValue = [];
  GenTablesList = [];
  ValTablesList = [];
  SumTablesList = [];
  Smparameterlist = [];
  NewSmparameterlist = [];
  Lmparameterlist = [];
  NewLmparameterlist = [];
  selectedValTables = [];
  selectedAssayTables = [];
  selectedGeneralTables = [];
  customdisable: boolean = true;
  disabledValue

  constructor(
    private formBuilder: FormBuilder,
    private _sharedService: SharedService,
    private uploadService: UploadService,
    private dropDownService: DropDownService,
    private templateService: TemplateService,
    private ref: ChangeDetectorRef
  ) {
    this._sharedService.emitChange(this.pageTitle);
    uploadService.disabledValue.subscribe(res => {
      this.disabledValue = res;
      // console.log(res, "dfgh")
    })

  }

  ngOnChanges(changes: SimpleChanges) {
  
    if ('analysistype' in changes) {
      if (changes.analysistype.currentValue) {
        this.analysistype = changes.analysistype.currentValue;
      }
   
    }
    
    this.createForm();
 
  }



  createForm()
   {
    this.analysisFormGroup = this.formBuilder.group({
      analysisType: [this.analysistype],
      Intra_Precision_CV: [null],
      projectCode: [null],
      Inter_Precision_CV: [null],
      multipleAnalytes: ['No'],
      Slope_cal_curve_reg: ['', [Validators.required, this.validateDecimalRange]],
      analyteNames: this.formBuilder.array([this.addAnalyteRows()]),
      files: [null],
      file_path: [null],
      analyteName: ['qPCR'],
     
    });
    this.ref.detectChanges();


    if (this.editvalues !== undefined) {
      const   qPCRvalue: AnalysisDetailsqPCR = new AnalysisDetailsqPCR()
      qPCRvalue.analysisType = this.analysistype;
      qPCRvalue.Intra_Precision_CV=this.editvalues.Intra_Precision_CV;
      qPCRvalue.Inter_Precision_CV=this.editvalues.Inter_Precision_CV;
      qPCRvalue.Slope_cal_curve_reg=this.editvalues.Slope_cal_curve_reg;
      qPCRvalue.projectCode = this.editvalues.projectCode;
      qPCRvalue.multipleAnalytes = this.editvalues.multipleAnalytes;
      qPCRvalue.analyteNames = this.editvalues.analyteNames;
      qPCRvalue.file_path = this.editvalues.file_path;
      qPCRvalue.files = this.editvalues.files;
      qPCRvalue.analyteName=this.editvalues.analyteName;

      for (let i = 1; i < this.editvalues.analyteNames.length; i++) {
        this.analyteNames.push(this.addAnalyteRows());
      }
      this.analysisFormGroup.setValue(qPCRvalue);

    }

  }

  validateDecimalRange(control) {
    // console.log(control.errors?.invalidDecimal)
    const value = parseFloat(control.value);
    const min = -3.6;
    const max = -3.1;

    if (isNaN(value)) {
      return { invalidDecimal: true, message: 'Please enter a valid decimal number.' };
    } else if (value < min || value > max) {
      return { invalidRange: true, message: 'Please enter a decimal between -3.6 and -3.1.' };
    }

    return null;
  }

  get formControls() { return this.analysisFormGroup.controls; }

  onSubmit(post: any) {

    // tslint:disable-next-line: max-line-length

    this.analysisDetails = Object.assign({}, this.analysisFormGroup.value);
    this.analysisDetails.userId = sessionStorage.getItem('username');
 
    if (this.analysisDetails.multipleAnalytes === 'Yes') {
      this.analysisDetails.multipleAnalytes = 'True'
    } else {
      this.analysisDetails.multipleAnalytes = 'False'
    }

    this.analysisDetails.analysisId = (new Date).getTime().toString();
   
      this.analysisDetails.analyteName=  this.analyteNames.value.map(res =>
      res= res.analyteName)
    this.post = post;
   
    this.formvalue.emit(this.analysisDetails);


  }

  addAnalyteRows()
  {
    return this.formBuilder.group({
    analyteName :['qPCR',[Validators.pattern('^[A-Za-z\x270-9 .,-]+$')]] ,
    LLOQValue:[''],
    ULOQValue:[''],
    })
  }


  getDropDownValues(key) 
  {
    return this.dropDownService.dropDownsMap.get(key);
  }

  get analyteNames()
   {
    return this.analysisFormGroup.get('analyteNames') as FormArray;
  }

  addAnalyte()
   {
    this.analyteNames.push(this.addAnalyteRows());
    // console.log(this.analysisFormGroup.controls.analyteNames.value.length)
  }

  removeAnalyte(index) 
  {
    this.analyteNames.removeAt(index);
  }

  resetAnalysis()
   {
 

  }

  multipleAnalyteValues()
   {
    const multipleChecked = this.analysisFormGroup.value.multipleAnalytes;
    const count = this.analysisFormGroup.value.analyteNames;
  
    if (multipleChecked === 'True' && count.length === 1) {
      console.log('error');
      // tslint:disable-next-line: no-unused-expression
      this.analysisFormGroup.invalid;
    } else {
      console.log('no error');
    }

  }



}