import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Blog } from '../../model/blog-type';
import { BlogServiceService } from '../../service/blog-service.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  blogDetail: Blog[] = [];

  constructor(
    private router: Router,
    private blogService: BlogServiceService
  ) { }

  ngOnInit() {
    this.blogService.getBlogs().subscribe((blogs: Blog[]) => {
      this.blogDetail = blogs;
   
    })
  }

  viewDetail(id: number) {
    this.router.navigate([('/extra-layout/blogDetail'), id]);
  }
}
